export default{
    vehicle_cat:
    [
    {"CategoryID":"33543","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Air Conditioning, Heating > AC Compressors"},
    {"CategoryID":"33545","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Air Conditioning, Heating > AC Controls"},
    {"CategoryID":"33546","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Air Conditioning, Heating > Blower Motors"},
    {"CategoryID":"33547","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Air Conditioning, Heating > Condensers, Evaporators"},
    {"CategoryID":"33548","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Air Conditioning, Heating > Heater Parts"},
    {"CategoryID":"46094","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Air Conditioning, Heating > Other"},
    {"CategoryID":"38634","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Air Intake, Fuel Delivery > Air Intake Systems"},
    {"CategoryID":"33550","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Air Intake, Fuel Delivery > Carburetors"},
    {"CategoryID":"33551","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Air Intake, Fuel Delivery > Carburetor Parts"},
    {"CategoryID":"33552","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Air Intake, Fuel Delivery > Chokes"},
    {"CategoryID":"33554","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Air Intake, Fuel Delivery > Fuel Injectors"},
    {"CategoryID":"33555","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Air Intake, Fuel Delivery > Fuel Pumps"},
    {"CategoryID":"33556","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Air Intake, Fuel Delivery > Fuel Tanks"},
    {"CategoryID":"33557","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Air Intake, Fuel Delivery > Sensors"},
    {"CategoryID":"33558","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Air Intake, Fuel Delivery > Throttle Body"},
    {"CategoryID":"42604","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Air Intake, Fuel Delivery > Other"},
    {"CategoryID":"173511","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Brakes & Brake Parts > Brake Component Packages"},
    {"CategoryID":"33561","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Brakes & Brake Parts > Brake Hoses"},
    {"CategoryID":"33567","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Brakes & Brake Parts > Brake Pads"},
    {"CategoryID":"61739","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Brakes & Brake Parts > Brake Shoes"},
    {"CategoryID":"33563","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Brakes & Brake Parts > Caliper Parts"},
    {"CategoryID":"33564","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Brakes & Brake Parts > Discs"},
    {"CategoryID":"33565","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Brakes & Brake Parts > Drums"},
    {"CategoryID":"33568","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Brakes & Brake Parts > Handbrake Cables"},
    {"CategoryID":"174078","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Brakes & Brake Parts > Handbrake Handles"},
    {"CategoryID":"33566","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Brakes & Brake Parts > Master Cylinders"},
    {"CategoryID":"33571","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Brakes & Brake Parts > Wheel Cylinders"},
    {"CategoryID":"42605","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Brakes & Brake Parts > Other"},
    {"CategoryID":"177697","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Charging, Starting Systems > Alternators & Generators"},
    {"CategoryID":"177698","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Charging, Starting Systems > Alternator & Generator Parts"},
    {"CategoryID":"179846","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Charging, Starting Systems > Batteries"},
    {"CategoryID":"179847","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Charging, Starting Systems > Battery Cables & Connectors"},
    {"CategoryID":"33575","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Charging, Starting Systems > Battery Trays"},
    {"CategoryID":"177699","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Charging, Starting Systems > Starters"},
    {"CategoryID":"177700","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Charging, Starting Systems > Starter Parts"},
    {"CategoryID":"33577","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Charging, Starting Systems > Voltage Regulators"},
    {"CategoryID":"33578","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Charging, Starting Systems > Other"},
    {"CategoryID":"108782","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Car Care & Cleaning > Air Fresheners"},
    {"CategoryID":"72201","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Car Care & Cleaning > Cleaning, Waxing & Valeting"},
    {"CategoryID":"72203","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Car Care & Cleaning > Touch-Up Materials"},
    {"CategoryID":"28641","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Car Care & Cleaning > Other"},
    {"CategoryID":"33601","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Cooling Systems > Clamps, Hoses"},
    {"CategoryID":"33600","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Cooling Systems > Fans"},
    {"CategoryID":"46095","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Cooling Systems > Oil Coolers"},
    {"CategoryID":"33602","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Cooling Systems > Radiators"},
    {"CategoryID":"33603","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Cooling Systems > Thermostats"},
    {"CategoryID":"33604","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Cooling Systems > Water Pumps"},
    {"CategoryID":"46096","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Cooling Systems > Other"},
    {"CategoryID":"72211","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Decals, Badges, Detailing > Badges"},
    {"CategoryID":"50445","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Decals, Badges, Detailing > Decals & Stickers"},
    {"CategoryID":"50450","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Decals, Badges, Detailing > Detailing Supplies"},
    {"CategoryID":"50452","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Decals, Badges, Detailing > Other"},
    {"CategoryID":"177702","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Electric Vehicle Parts > Accessories"},
    {"CategoryID":"177704","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Electric Vehicle Parts > Chargers & Service Equipment"},
    {"CategoryID":"177705","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Electric Vehicle Parts > Controllers"},
    {"CategoryID":"177706","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Electric Vehicle Parts > Converters & Inverters"},
    {"CategoryID":"177707","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Electric Vehicle Parts > Conversion Kits"},
    {"CategoryID":"177703","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Electric Vehicle Parts > Electric Vehicle Batteries"},
    {"CategoryID":"177708","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Electric Vehicle Parts > Motors"},
    {"CategoryID":"177709","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Electric Vehicle Parts > Other"},
    {"CategoryID":"33595","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > ECUs, Chips, Cruise Control > Cruise Control Units"},
    {"CategoryID":"33596","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > ECUs, Chips, Cruise Control > ECUs"},
    {"CategoryID":"33597","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > ECUs, Chips, Cruise Control > Performance Chips"},
    {"CategoryID":"33598","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > ECUs, Chips, Cruise Control > Other"},
    {"CategoryID":"38656","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Engines, Components > Belts, Pulleys & Brackets"},
    {"CategoryID":"33613","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Engines, Components > Block Parts"},
    {"CategoryID":"33614","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Engines, Components > Camshafts, Lifters & Parts"},
    {"CategoryID":"33615","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Engines, Components > Complete Engines"},
    {"CategoryID":"33616","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Engines, Components > Crankshafts & Parts"},
    {"CategoryID":"33617","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Engines, Components > Cylinder Heads & Parts"},
    {"CategoryID":"33619","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Engines, Components > Engine Bearings"},
    {"CategoryID":"33620","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Engines, Components > Engine Rebuilding Kits"},
    {"CategoryID":"50454","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Engines, Components > Motor Mounts"},
    {"CategoryID":"33622","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Engines, Components > Oil Filler Caps"},
    {"CategoryID":"38657","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Engines, Components > Oil Pans"},
    {"CategoryID":"6778","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Engines, Components > Oil Pumps"},
    {"CategoryID":"33623","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Engines, Components > Pistons, Rings, Rods & Parts"},
    {"CategoryID":"33624","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Engines, Components > Rocker Arms & Parts"},
    {"CategoryID":"33625","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Engines, Components > Timing Components"},
    {"CategoryID":"33626","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Engines, Components > Vacuum Pumps"},
    {"CategoryID":"33627","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Engines, Components > Valve Covers"},
    {"CategoryID":"33621","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Engines, Components > Valves & Parts"},
    {"CategoryID":"46098","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Engines, Components > Other"},
    {"CategoryID":"33629","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Exhausts > Cat Converters"},
    {"CategoryID":"33630","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Exhausts > Exhaust Systems"},
    {"CategoryID":"33632","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Exhausts > Manifolds & Headers"},
    {"CategoryID":"33636","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Exhausts > Mufflers"},
    {"CategoryID":"33633","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Exhausts > Pipes & Tips"},
    {"CategoryID":"42610","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Exhausts > Other"},
    {"CategoryID":"33639","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Exterior > Antennas"},
    {"CategoryID":"36475","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Exterior > Body Kits"},
    {"CategoryID":"33646","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Exterior > Bonnets"},
    {"CategoryID":"33656","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Exterior > Boot Lids & Parts"},
    {"CategoryID":"33640","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Exterior > Bumper Bars"},
    {"CategoryID":"178075","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Exterior > Bullbars"},
    {"CategoryID":"50456","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Exterior > Car Covers"},
    {"CategoryID":"179849","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Exterior > Door Hinge Conversion Kits"},
    {"CategoryID":"179850","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Exterior > Exterior Door Panels & Frames"},
    {"CategoryID":"179851","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Exterior > Exterior Door Handles"},
    {"CategoryID":"33644","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Exterior > Fenders"},
    {"CategoryID":"33645","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Exterior > Grills"},
    {"CategoryID":"33648","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Exterior > Lock Mechanisms"},
    {"CategoryID":"33649","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Exterior > Mirrors"},
    {"CategoryID":"33654","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Exterior > Mouldings, Trim"},
    {"CategoryID":"33651","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Exterior > Racks"},
    {"CategoryID":"33650","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Exterior > Running Boards"},
    {"CategoryID":"50455","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Exterior > Splash Guards & Mud Flaps"},
    {"CategoryID":"33638","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Exterior > Spoilers, Wings"},
    {"CategoryID":"33652","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Exterior > Sunroofs"},
    {"CategoryID":"33647","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Exterior > Tailgates & Liftgates"},
    {"CategoryID":"33653","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Exterior > Tow Bars & Winches"},
    {"CategoryID":"33657","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Exterior > Windshield Wipers"},
    {"CategoryID":"42611","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Exterior > Other"},
    {"CategoryID":"33659","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Filters > Air"},
    {"CategoryID":"33660","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Filters > Fuel"},
    {"CategoryID":"33661","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Filters > Oil"},
    {"CategoryID":"33662","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Filters > Transmission"},
    {"CategoryID":"33663","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Filters > Other"},
    {"CategoryID":"33666","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Gaskets > Exhaust Gaskets"},
    {"CategoryID":"33667","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Gaskets > Full Gasket Sets"},
    {"CategoryID":"33665","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Gaskets > Head Gaskets"},
    {"CategoryID":"46099","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Gaskets > Other"},
    {"CategoryID":"38660","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Gauges > Boost Gauges"},
    {"CategoryID":"33673","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Gauges > Clocks"},
    {"CategoryID":"33674","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Gauges > Fuel Gauges"},
    {"CategoryID":"33675","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Gauges > Instrument Clusters"},
    {"CategoryID":"33676","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Gauges > Oil Pressure Gauges"},
    {"CategoryID":"33677","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Gauges > Panel Gauges"},
    {"CategoryID":"33678","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Gauges > Speedometers"},
    {"CategoryID":"33679","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Gauges > Tachometers"},
    {"CategoryID":"33682","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Gauges > Temp Gauges"},
    {"CategoryID":"33680","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Gauges > Vacuum Gauges"},
    {"CategoryID":"33681","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Gauges > Volt Meters"},
    {"CategoryID":"46100","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Gauges > Other"},
    {"CategoryID":"33685","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Glass > Seals"},
    {"CategoryID":"63689","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Glass > Window Tint"},
    {"CategoryID":"33684","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Glass > Windows"},
    {"CategoryID":"33686","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Glass > Windshields"},
    {"CategoryID":"6781","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Glass > Other"},
    {"CategoryID":"33688","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Ignition Systems > Caps, Rotors, Contacts"},
    {"CategoryID":"33689","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Ignition Systems > Coils, Modules, Pickups"},
    {"CategoryID":"33690","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Ignition Systems > Distributors"},
    {"CategoryID":"33692","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Ignition Systems > Ignition Wires"},
    {"CategoryID":"40016","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Ignition Systems > Key Blanks"},
    {"CategoryID":"33693","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Ignition Systems > Spark Plugs & Glow Plugs"},
    {"CategoryID":"46101","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Ignition Systems > Other"},
    {"CategoryID":"33695","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Interior > Consoles"},
    {"CategoryID":"63691","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Interior > Cup Holders"},
    {"CategoryID":"40017","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Interior > Dash Parts"},
    {"CategoryID":"33697","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Interior > Floor Mats, Carpets"},
    {"CategoryID":"33698","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Interior > Glove Boxes"},
    {"CategoryID":"179848","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Interior > Interior Door Handles"},
    {"CategoryID":"33696","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Interior > Interior Door Panels & Parts"},
    {"CategoryID":"33699","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Interior > Mirrors"},
    {"CategoryID":"33700","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Interior > Pedals"},
    {"CategoryID":"33701","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Interior > Seats"},
    {"CategoryID":"33702","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Interior > Seat Covers"},
    {"CategoryID":"33703","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Interior > Shift Knobs, Boots"},
    {"CategoryID":"33704","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Interior > Steering Wheels & Horns"},
    {"CategoryID":"46102","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Interior > Sun Visors"},
    {"CategoryID":"50459","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Interior > Switches & Controls"},
    {"CategoryID":"33705","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Interior > Trim"},
    {"CategoryID":"40018","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Interior > Window Cranks"},
    {"CategoryID":"33706","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Interior > Window Motors"},
    {"CategoryID":"42612","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Interior > Other"},
    {"CategoryID":"33708","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Lights, Indicators > Corner Lights"},
    {"CategoryID":"38661","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Lights, Indicators > Covers"},
    {"CategoryID":"33711","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Lights, Indicators > Dash Lights"},
    {"CategoryID":"33709","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Lights, Indicators > Fog/Exterior Lights"},
    {"CategoryID":"33710","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Lights, Indicators > Headlights"},
    {"CategoryID":"33712","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Lights, Indicators > Interior Lights"},
    {"CategoryID":"33713","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Lights, Indicators > LED Lights"},
    {"CategoryID":"184669","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Lights, Indicators > Light Bars"},
    {"CategoryID":"33714","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Lights, Indicators > Neon Lights"},
    {"CategoryID":"33716","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Lights, Indicators > Tail Lights"},
    {"CategoryID":"33717","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Lights, Indicators > Turn Signals"},
    {"CategoryID":"36476","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Lights, Indicators > Xenon Lights"},
    {"CategoryID":"42613","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Lights, Indicators > Other"},
    {"CategoryID":"421","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Number Plates"},
    {"CategoryID":"36680","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Performance Parts > Air Filters, Induction Kits"},
    {"CategoryID":"32624","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Performance Parts > Engine Modifications"},
    {"CategoryID":"32094","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Performance Parts > Mod Chips"},
    {"CategoryID":"33742","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Performance Parts > Turbo Chargers & Parts"},
    {"CategoryID":"102567","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Performance Parts > V6"},
    {"CategoryID":"158509","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Performance Parts > V8"},
    {"CategoryID":"107066","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Performance Parts > Other"},
    {"CategoryID":"75329","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Safety & Security > Anti-Theft Car Alarms"},
    {"CategoryID":"33722","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Safety & Security > Fire Extinguishers"},
    {"CategoryID":"53908","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Safety & Security > Car Keys, Fobs & Remotes"},
    {"CategoryID":"177710","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Safety & Security > Safety Airbags"},
    {"CategoryID":"177711","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Safety & Security > Safety Airbag Parts"},
    {"CategoryID":"50458","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Safety & Security > Seat Belt Shoulder Pads"},
    {"CategoryID":"33725","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Safety & Security > Seat Belts"},
    {"CategoryID":"184627","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Safety & Security > Security Posts"},
    {"CategoryID":"184628","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Safety & Security > Steering Wheel Locks"},
    {"CategoryID":"184629","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Safety & Security > Tow Hitch Locks"},
    {"CategoryID":"184630","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Safety & Security > Wheel Clamps"},
    {"CategoryID":"6779","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Safety & Security > Other Safety & Security"},
    {"CategoryID":"33580","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Suspension, Steering > Ball Joints"},
    {"CategoryID":"33581","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Suspension, Steering > Caster/Camber Kits"},
    {"CategoryID":"33582","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Suspension, Steering > Coil Springs"},
    {"CategoryID":"33583","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Suspension, Steering > Control Arms"},
    {"CategoryID":"33584","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Suspension, Steering > Leaf Springs"},
    {"CategoryID":"33585","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Suspension, Steering > Lift Kits"},
    {"CategoryID":"33587","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Suspension, Steering > Pitman & Idler Arms"},
    {"CategoryID":"33588","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Suspension, Steering > Power Steering Pumps"},
    {"CategoryID":"174092","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Suspension, Steering > Shock Absorbers/Dampers"},
    {"CategoryID":"33589","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Suspension, Steering > Steering Racks, Gear Boxes"},
    {"CategoryID":"33590","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Suspension, Steering > Shocks, Struts"},
    {"CategoryID":"33591","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Suspension, Steering > Strut Bars"},
    {"CategoryID":"33592","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Suspension, Steering > Sway Bars"},
    {"CategoryID":"33593","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Suspension, Steering > Tie Rod Ends"},
    {"CategoryID":"42609","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Suspension, Steering > Other"},
    {"CategoryID":"33727","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Transmission > Automatic Transmission"},
    {"CategoryID":"33728","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Transmission > Axle Parts"},
    {"CategoryID":"33730","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Transmission > Clutches"},
    {"CategoryID":"33729","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Transmission > CV Joints"},
    {"CategoryID":"33731","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Transmission > Differentials"},
    {"CategoryID":"174105","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Transmission > Gear Boxes & Gear Box Parts"},
    {"CategoryID":"33733","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Transmission > Manual Transmission"},
    {"CategoryID":"33735","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Transmission > Pressure Plates"},
    {"CategoryID":"33736","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Transmission > Shifters"},
    {"CategoryID":"63692","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Transmission > Torque Converters"},
    {"CategoryID":"46103","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Transmission > Transmission Rebuild Kits"},
    {"CategoryID":"33738","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Transmission > Universal Joints, Driveshafts"},
    {"CategoryID":"46104","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Transmission > Other"},
    {"CategoryID":"10073","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Vintage Car & Truck Parts"},
    {"CategoryID":"33744","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Wheels, Tyres > Hub Caps"},
    {"CategoryID":"33746","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Wheels, Tyres > Tyre Accessories"},
    {"CategoryID":"66471","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Wheels, Tyres > Tyres"},
    {"CategoryID":"33747","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Wheels, Tyres > Valve Stems, Caps"},
    {"CategoryID":"170141","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Wheels, Tyres > Wheel Hubs & Bearings"},
    {"CategoryID":"66479","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Wheels, Tyres > Wheel & Tyre Packages"},
    {"CategoryID":"43953","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Wheels, Tyres > Wheels"},
    {"CategoryID":"42614","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Wheels, Tyres > Other"},
    {"CategoryID":"40019","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Wholesale, Bulk Lots"},
    {"CategoryID":"6763","Category Path":"Vehicle Parts & Accessories > Car & Truck Parts > Other"}
    ]
    
}
